import classes from "./SectionTitle.module.css";

type Props = {
  title: string;
};

const SectionTitle = (props: Props) => {
  const { title } = props;

  return (
    <div className={classes.heading}>
      <h2 className={classes.title}>{title}</h2>
    </div>
  );
};

export default SectionTitle;
