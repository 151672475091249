import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
} from "@mui/material";
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { BENEFICIARIO, IBAN } from "../../constants";
import { useIsSmallScreen } from "../../hooks/useMediaQuery";
import AppSection from "../AppSection/AppSection";
import SectionTitle from "../SectionTitle/SectionTitle";
import classes from "./HoneymoonSection.module.css";
import fuji from "./res/fuji.jpg";
import kumano from "./res/kumano.jpg";
import shibuya from "./res/shibuya.jpg";
import shirakawa_go from "./res/shirakawa-go.jpg";
import tokyo from "./res/tokyo.jpg";
import torii from "./res/torii.jpg";
import tradition from "./res/tradition.jpg";

const HoneymoonSection = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const isSmallScreen = useIsSmallScreen();

  const copyToClipboard = (data: string) => {
    navigator.clipboard.writeText(data);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <AppSection>
      <SectionTitle title="Lista Nozze" />
      <div className={classes.sectionContent}>
        <div className={classes.text}>
          <p>
            Avervi con noi nel giorno della nostra festa sarà il regalo più
            significativo.
            <br />
            Se vi fa piacere, potete aiutarci a realizzare un sogno che
            coltiviamo da tempo: il nostro primo viaggio fuori dal continente,
            alla scoperta delle meraviglie del Giappone.
          </p>
        </div>
        <div className={classes.fields}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="iban">Il nostro IBAN</InputLabel>
            <OutlinedInput
              id="iban"
              label="Il nostro IBAN"
              multiline
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="copia negli appunti"
                    onClick={() => copyToClipboard(IBAN)}
                    edge="end"
                    size="small"
                  >
                    <ContentCopyOutlinedIcon sx={{ fontSize: "1rem" }} />
                  </IconButton>
                </InputAdornment>
              }
              readOnly
              type="text"
              value={IBAN}
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="beneficiario">Beneficiario</InputLabel>
            <OutlinedInput
              id="beneficiario"
              label="Beneficiario"
              multiline
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="copia negli appunti"
                    onClick={() => copyToClipboard(BENEFICIARIO)}
                    edge="end"
                    size="small"
                  >
                    <ContentCopyOutlinedIcon sx={{ fontSize: "1rem" }} />
                  </IconButton>
                </InputAdornment>
              }
              readOnly
              type="text"
              value={BENEFICIARIO}
            />
          </FormControl>
        </div>
        <div className={classes.pictures}>
          <Carousel
            autoPlay={true}
            className={
              isSmallScreen
                ? classes.carousel
                : [classes.carousel, classes.carousel_desktop].join(" ")
            }
            emulateTouch
            infiniteLoop={true}
            interval={3000}
            showStatus={false}
            showThumbs={false}
          >
            <div className={classes.picWrapper}>
              <img className={classes.pic} src={tokyo} alt="tokyo" />
            </div>
            <div className={classes.picWrapper}>
              <img className={classes.pic} src={kumano} alt="kumano" />
            </div>
            <div className={classes.picWrapper}>
              <img className={classes.pic} src={torii} alt="torii" />
            </div>
            <div className={classes.picWrapper}>
              <img className={classes.pic} src={fuji} alt="fuji" />
            </div>
            <div className={classes.picWrapper}>
              <img className={classes.pic} src={tradition} alt="tradition" />
            </div>
            <div className={classes.picWrapper}>
              <img
                className={classes.pic}
                src={shirakawa_go}
                alt="shirakawa-go"
              />
            </div>
            <div className={classes.picWrapper}>
              <img className={classes.pic} src={shibuya} alt="shibuya" />
            </div>
          </Carousel>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          autoHideDuration={4000}
          message="Copiato negli appunti"
          onClose={handleSnackbarClose}
          open={snackbarOpen}
        />
      </div>
    </AppSection>
  );
};

export default HoneymoonSection;
