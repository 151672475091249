import { RefObject, useEffect, useRef } from "react";

const useIntersectionObserver = (
  targetRef: RefObject<Element>,
  callback: IntersectionObserverCallback,
  options?: IntersectionObserverInit
) => {
  const observerRef = useRef(
    new IntersectionObserver(callback, options)
  );

  useEffect(() => {
    if (!targetRef.current) return;
    const observer = observerRef.current;
    observer.observe(targetRef.current);

    // Cleanup
    return () => {
      observer.disconnect();
    };
  }, [targetRef]);
};

export default useIntersectionObserver;
