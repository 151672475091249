import { createTheme } from "@mui/material";

const defaultFonts = [
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

const enhancedFonts = ["Montserrat", ...defaultFonts];

const theme = createTheme({
  palette: {
    // main: green, dark: dark green
    primary: { main: "#4C956C", dark: "#2C6E49" },
    // main: green
    info: { main: "#4C956C" },
    // main: ancient pink, light: cream
    secondary: { light: "#FEFEE3", main: "#FFC9B9" },
    // main: caramel
    warning: { main: "#D68C45" },
    // main: dark green, light: green
    success: { main: "#2C6E49", light: "#4C956C" },
  },
  typography: {
    fontFamily: enhancedFonts.join(","),
    button: {
      fontFamily: enhancedFonts.join(","),
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "Roboto Mono,consolas,monospace",
        },
      },
    },
    MuiLink: {
      styleOverrides: { root: { fontFamily: enhancedFonts.join(",") } },
    },
    MuiSnackbarContent: {
      styleOverrides: { root: { fontFamily: enhancedFonts.join(",") } },
    },
  },
});

export default theme;
