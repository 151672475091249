import { Circle } from "@mui/icons-material";
import type { SxProps } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {
  CSSProperties,
  TouchEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SLIDESHOW_INTERVAL } from "../../constants";
import useMediaQuery from "../../hooks/useMediaQuery";
import { pictures, pictures_mobile } from "./res";
import classes from "./Slideshow.module.css";

const NAV_BUTTON_SELECTED_STYLE: SxProps = {
  color: "white",
  fontSize: "0.6rem",
};
const NAV_BUTTON_STYLE: SxProps = {
  ...NAV_BUTTON_SELECTED_STYLE,
  opacity: 0.4,
};
const SWIPE_THRESHOLD = 25;

const Slideshow = () => {
  const [currentPicture, setCurrentPicture] = useState<string | null>(null);
  const [touchStartX, setTouchStartX] = useState<number>(0);
  const [touchEndX, setTouchEndX] = useState<number>(0);
  const timeoutRef = useRef<NodeJS.Timer | null>(null);
  const isPortraitRatio = useMediaQuery("(max-aspect-ratio: 1/1)");
  const slideshowPictures = isPortraitRatio ? pictures_mobile : pictures;
  const { getArray, getNext, getPrevious, getFirst } =
    useArrayHandlers(slideshowPictures);

  useEffect(() => {
    setCurrentPicture(getFirst());
  }, [getFirst]);

  useEffect(() => {
    if (!currentPicture) return;

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setCurrentPicture((previous) => getNext(previous));
    }, SLIDESHOW_INTERVAL);

    const currentTimeout = timeoutRef.current;

    return () => {
      if (currentTimeout) clearTimeout(currentTimeout);
    };
  }, [currentPicture, getNext]);

  const handleTouchStart = (e: TouchEvent) => {
    setTouchStartX(e.changedTouches[0].screenX);
  };

  const handleTouchEnd = (e: TouchEvent) => {
    setTouchEndX(e.changedTouches[0].screenX);
    handleSwipe();
  };

  const handleSwipe = () => {
    if (touchEndX < touchStartX - SWIPE_THRESHOLD) {
      setCurrentPicture(getNext(currentPicture));
    } else if (touchEndX > touchStartX + SWIPE_THRESHOLD) {
      setCurrentPicture(getPrevious(currentPicture));
    }
  };

  return (
    <div
      className={classes.root}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      {getArray().map((picture) => (
        <div
          className={classes.image}
          key={picture}
          style={getStyleForPicture(picture, currentPicture)}
        ></div>
      ))}
      <div className={classes.buttonBar}>
        {getArray().map((picture) => (
          <IconButton
            aria-label="view picture"
            color="warning"
            key={picture}
            onClick={() => {
              setCurrentPicture(picture);
            }}
            size="small"
          >
            <Circle
              sx={
                picture === currentPicture
                  ? NAV_BUTTON_SELECTED_STYLE
                  : NAV_BUTTON_STYLE
              }
            />
          </IconButton>
        ))}
      </div>
    </div>
  );
};

const getStyleForPicture = (picture: string, currentPicture: string | null) => {
  const style: CSSProperties = {
    background: `linear-gradient(to bottom,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.4)), url("${picture}")`,
    backgroundPosition: "50% 35%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  if (picture === currentPicture) {
    style.transform = "rotate(-4deg) scale(1.25)";
  } else {
    style.opacity = "0";
  }
  return style;
};

const getArrayHandlers = <T extends unknown>(array: T[]) => {
  const getArray = () => array;
  const getFirst = () => array[0];
  const getLast = () => array[array.length - 1];
  const getNext = (current: T | null) => {
    if (!current) return array[0];
    const index = array.indexOf(current);
    // Return next
    if (array.length - 1 > index) return array[index + 1];
    // Last element: loop to first
    return getFirst();
  };
  const getPrevious = (current: T | null) => {
    if (!current) return array[0];
    const index = array.indexOf(current);
    const previousIndex = index - 1;
    // Return previous
    if (previousIndex >= 0) return array[previousIndex];
    // First element: loop to last
    return getLast();
  };
  return { getArray, getNext, getPrevious, getFirst, getLast };
};

const useArrayHandlers = <T extends unknown>(array: T[]) => {
  const arrayDependency = JSON.stringify(array);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => getArrayHandlers(array), [arrayDependency]);
};

export default Slideshow;
