import { RefObject, useCallback } from "react";

const useCoverResizeCallback = (
  coverRef: RefObject<Element>,
  baseClass: string,
  minimizedClass: string
) => {
  return useCallback<IntersectionObserverCallback>(
    ([entry], _observer) => {
      if (!coverRef.current) return;
      if (entry.target !== coverRef.current) return;
      if (entry.intersectionRatio >= 0.3) {
        coverRef.current.className = baseClass;
      } else {
        coverRef.current.className = [baseClass, minimizedClass].join(" ");
      }
    },
    [coverRef, baseClass, minimizedClass]
  );
};

export default useCoverResizeCallback;
