import React, { ComponentProps } from "react";
import classes from "./Calendar.module.css";
import CalendarCell from "./CalendarCell";
import { ReactComponent as WeddingRingsSmall } from "../../resources/wedding_rings_small.svg";
import CalendarDot from "./CalendarDot";

const DOT = <CalendarDot />;

const Calendar = (props: ComponentProps<"div">) => {
  const { className } = props;
  return (
    <div className={classes.root}>
      {/* <span className={classes.heading}>Domenica, 18 Giugno 2023</span> */}
      <div {...props} className={classes.grid}>
        {/* Week days */}
        <CalendarCell className={classes.weekDay}>L</CalendarCell>
        <CalendarCell className={classes.weekDay}>M</CalendarCell>
        <CalendarCell className={classes.weekDay}>M</CalendarCell>
        <CalendarCell className={classes.weekDay}>G</CalendarCell>
        <CalendarCell className={classes.weekDay}>V</CalendarCell>
        <CalendarCell className={classes.weekDay}>S</CalendarCell>
        <CalendarCell className={classes.weekDay}>D</CalendarCell>
        {/* Week 1 */}
        <CalendarCell />
        <CalendarCell />
        <CalendarCell />
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        {/* Week 2 */}
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        {/* Week 3 */}
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>
          <span className={classes.ringsIcon}>
            <WeddingRingsSmall />
          </span>
        </CalendarCell>
        {/* Week 4 */}
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        {/* Week 5 */}
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell>{DOT}</CalendarCell>
        <CalendarCell />
        <CalendarCell />
      </div>
    </div>
  );
};

export default Calendar;
