import { EmailOutlined, PhoneOutlined } from "@mui/icons-material";
import { Chip, Divider, Link, Paper } from "@mui/material";
import { MouseEvent } from "react";
import { mail_f, mail_j, phn_f, phn_j } from "../../constants";
import AppSection from "../AppSection/AppSection";
import Attributions from "../Attributions/Attributions";
import SectionTitle from "../SectionTitle/SectionTitle";
import classes from "./ContactsSection.module.css";

const eid_phn_j = "phn_j";
const eid_phn_f = "phn_f";
const eid_mail_j = "mail_j";
const eid_mail_f = "mail_f";

const ContactsSection = () => {
  const phoneLinkClickHandler = (event: MouseEvent) => {
    const elementId = event.currentTarget.id;
    if (!elementId) return;

    let target: string | undefined;
    if (elementId === eid_phn_f) {
      target = phn_f;
    }
    if (elementId === eid_phn_j) {
      target = phn_j;
    }
    if (!target || !elementId) return;
    window.location.href = `tel:${window.atob(target)}`;
  };

  const mailLinkClickHandler = (event: MouseEvent) => {
    const elementId = event.currentTarget.id;
    if (!elementId) return;

    let target: string | undefined;
    if (elementId === eid_mail_f) {
      target = mail_f;
    }
    if (elementId === eid_mail_j) {
      target = mail_j;
    }
    if (!target || !elementId) return;
    window.location.href = `mailto:${window.atob(target)}`;
  };

  return (
    <AppSection>
      <SectionTitle title="Contatti" />
      <div className={classes.sectionContent}>
        Nel caso abbiate domande, dubbi, richieste particolari, o vogliate
        semplicemente scriverci.
        <div>
          <Paper className={classes.paper}>
            <div className={classes.paperContent}>
              <div className={classes.contactsRow}>
                <div className={classes.contactsStack}>
                  <div className={classes.stackItem}>
                    <Chip
                      label="Jacopo"
                      sx={{
                        backgroundColor: "var(--GREEN)",
                        color: "var(--IVORY)",
                        fontWeight: "500",
                        minWidth: "12ch",
                      }}
                    />
                  </div>
                  <div className={classes.stackItem}>
                    <Link
                      alignItems="center"
                      component="button"
                      display="flex"
                      gap="0.25rem"
                      id={eid_mail_j}
                      onClick={mailLinkClickHandler}
                      underline="always"
                    >
                      <EmailOutlined />
                      Invia Mail
                    </Link>
                  </div>
                  <div className={classes.stackItem}>
                    <Link
                      alignItems="center"
                      component="button"
                      display="flex"
                      gap="0.25rem"
                      id={eid_phn_j}
                      onClick={phoneLinkClickHandler}
                      underline="always"
                    >
                      <PhoneOutlined />
                      Chiama
                    </Link>
                  </div>
                </div>
                <Divider orientation="vertical" variant="middle" flexItem />
                <div className={classes.contactsStack}>
                  <div className={classes.stackItem}>
                    <Chip
                      label="Francesca"
                      sx={{
                        backgroundColor: "var(--GREEN)",
                        color: "var(--IVORY)",
                        fontWeight: "500",
                        minWidth: "12ch",
                      }}
                    />
                  </div>
                  <div className={classes.stackItem}>
                    <Link
                      alignItems="center"
                      component="button"
                      display="flex"
                      gap="0.25rem"
                      id={eid_mail_f}
                      onClick={mailLinkClickHandler}
                      underline="always"
                    >
                      <EmailOutlined />
                      Invia Mail
                    </Link>
                  </div>
                  <div className={classes.stackItem}>
                    <Link
                      alignItems="center"
                      component="button"
                      display="flex"
                      gap="0.25rem"
                      id={eid_phn_f}
                      onClick={phoneLinkClickHandler}
                      underline="always"
                    >
                      <PhoneOutlined />
                      Chiama
                    </Link>
                  </div>
                </div>
              </div>
              <Divider className={classes.divider} variant="middle">
                <Chip
                  label="Indirizzo"
                  sx={{
                    backgroundColor: "var(--PEACH)",
                    color: "var(--IVORY)",
                    fontWeight: "500",
                    minWidth: "12ch",
                  }}
                />
              </Divider>
              <div className={classes.addressStack}>
                <span>Via Dante Alighieri 34</span>
                <span>22100 Como CO</span>
              </div>
            </div>
          </Paper>
        </div>
      </div>
      <Attributions />
    </AppSection>
  );
};

export default ContactsSection;
