import {
  FACTOR_DAYS,
  FACTOR_HOURS,
  FACTOR_MINUTES,
  FACTOR_SECONDS,
  WEDDING_DATE_TIMESTAMP,
} from "../../constants";

export const computeCountdownInMs = () => WEDDING_DATE_TIMESTAMP - Date.now();

export const computeCountdownParts = (milliseconds: number) => {
  const days = Math.floor(milliseconds / FACTOR_DAYS);
  const daysRemainder = milliseconds % FACTOR_DAYS;
  const hours = Math.floor(daysRemainder / FACTOR_HOURS);
  const hoursRemainder = daysRemainder % FACTOR_HOURS;
  const minutes = Math.floor(hoursRemainder / FACTOR_MINUTES);
  const minutesRemainder = hoursRemainder % FACTOR_MINUTES;
  const seconds = Math.floor(minutesRemainder / FACTOR_SECONDS);
  return { days, hours, minutes, seconds };
};
