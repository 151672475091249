import classes from "./CountdownFigure.module.css";

type Props = {
  value: number;
  figure: string;
};

const CountdownFigure = (props: Props) => {
  const { figure, value } = props;

  return (
    <div className={classes.root}>
      <span className={classes.value}>{value}</span>
      <span>{figure}</span>
    </div>
  );
};

export default CountdownFigure;
