import {
  AlternateEmailOutlined,
  EventAvailableOutlined,
  FavoriteBorderOutlined,
  PlaceOutlined,
  ScheduleOutlined,
  VolunteerActivismOutlined,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import RsvpRoundedIcon from "@mui/icons-material/RsvpRounded";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { ReactNode, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { setActiveSection } from "../../features/ui";
import { Section } from "../../features/ui/types";
import classes from "./NavigationDrawer.module.css";

type SectionInfo = {
  id: Section;
  label: string;
  icon: ReactNode;
};

const mainSections: SectionInfo[] = [
  { id: "home", label: "Home", icon: <FavoriteBorderOutlined /> },
  { id: "date", label: "Data", icon: <EventAvailableOutlined /> },
  { id: "location", label: "Location", icon: <PlaceOutlined /> },
  { id: "schedule", label: "Programma", icon: <ScheduleOutlined /> },
  {
    id: "honeymoon",
    label: "Lista Nozze",
    icon: <VolunteerActivismOutlined />,
  },
];
const otherSections: SectionInfo[] = [
  { id: "rvsp", label: "Ci sarete?", icon: <RsvpRoundedIcon /> },
  { id: "contacts", label: "Contatti", icon: <AlternateEmailOutlined /> },
];
const drawerWidth = 240;
const listItemStyle = {
  color: "var(--DARK-GREEN)",
  "& .MuiListItemIcon-root": {
    color: "var(--GREEN)",
  },
};
const menuBackDropFilter = "blur(10px) saturate(150%) brightness(80%)";

const NavigationDrawer = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const dispatch = useAppDispatch();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleItemClick = (id: Section) => {
    dispatch(setActiveSection(id));
    setDrawerOpen(false);
  };

  const drawer = (
    <div>
      <div className={classes.titleWrapper}>
        <h3 className={classes.title}>Jacopo e Francesca</h3>
      </div>
      <Divider />
      <List>
        {mainSections.map(({ icon, id, label }) => (
          <ListItem key={id} disablePadding sx={listItemStyle}>
            <ListItemButton
              onClick={() => {
                handleItemClick(id);
              }}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {otherSections.map(({ icon, id, label }) => (
          <ListItem key={id} disablePadding sx={listItemStyle}>
            <ListItemButton
              onClick={() => {
                handleItemClick(id);
              }}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container = document.body;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "rgba(0,0,0,0)",
          boxShadow: "none",
          pointerEvents: "none",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              backdropFilter: menuBackDropFilter,
              border: "1px solid white",
              borderRadius: "4px",
              pointerEvents: "all",
              "& .MuiTouchRipple-root .MuiTouchRipple-child": {
                borderRadius: "4px",
              },
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="site navigation"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              background:
                "linear-gradient(90deg, rgba(255,243,235,1) 0%, rgba(254,254,239,1) 100%)",
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default NavigationDrawer;
