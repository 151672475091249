import { useRef } from "react";
import useCoverResizeCallback from "../../hooks/useCoverResizeCallback";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import Slideshow from "../Slideshow/Slideshow";

import classes from "./Cover.module.css";

type Props = {
  className?: string;
};

const Cover = (props: Props) => {
  const { className } = props;
  const coverRef = useRef<HTMLDivElement>(null);

  const coverResizeCallback = useCoverResizeCallback(
    coverRef,
    classes.cover,
    classes.cover_minimized
  );

  useIntersectionObserver(coverRef, coverResizeCallback, { threshold: 0.3 });

  return (
    <div className={[classes.cover, className].join(" ")} ref={coverRef}>
      <Slideshow />
      <div className={classes.text}>
        <h1 className={classes.title}>Jacopo  e  Francesca</h1>
      </div>
    </div>
  );
};

export default Cover;
